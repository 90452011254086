import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";

// Icons
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import advisoryTopTalents from "../assets/images/advisory-top-talent.png";
import advisoryAdvisorsEngineers from "../assets/images/advisory-advisors-and-engineers.png";
import advisorySeniorPractitioners from "../assets/images/advisory-senior-practitioners.png";
import advisoryToughestProblems from "../assets/images/advisory-toughest-problems.png";
import { Link } from "react-router-dom";

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.25,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const AdvisoryOffering = ({ imgSrc, imgAlt, content, borderClass }) => (
  <div className={`text-center p-4 text-sm ${borderClass}`}>
    <div className="flex justify-center mb-4">
      <img src={imgSrc} alt={imgAlt} className="w-[80px]" />
    </div>
    {content}
  </div>
);

const AdvisoryServices = () => {
  const advisoryOfferings = [
    {
      imgSrc: advisoryTopTalents,
      imgAlt: 'Advisory Top Talents',
      content: (
        <>
          Our team consists of best-in-class strategists and engineers from: 
          <span className="font-bold"> McKinsey, BCG, Tucows, ThoughtWorks, etc.</span>
          <p className="mt-4">We <span className="font-bold">only</span> work in Financial Services</p>
        </>
      ),
      borderClass: 'md:border-r-2',
    },
    {
      imgSrc: advisoryAdvisorsEngineers,
      imgAlt: 'Advisors and Engineers',
      content: (
        <>
          We <span className="font-bold">pair advisors and engineers</span> on <span className="font-bold">every engagement</span>
          <p className="mt-4">We work <span className="font-bold">top down</span> and <span className="font-bold">bottom up</span> to ensure technology drives business value</p>
        </>
      ),
      borderClass: 'md:border-r-2',
    },
    {
      imgSrc: advisorySeniorPractitioners,
      imgAlt: 'Advisory Senior Practitioners',
      content: (
        <>
          Our Advisory hierarchy is <span className="font-bold">inverted by design</span>
          <p className="mt-4">When you hire RAVL you get <span className="font-bold">senior practitioners</span> on every engagement</p>
          <p className="mt-4">Experience matters</p>
        </>
      ),
      borderClass: '',
    },
    {
      imgSrc: advisoryToughestProblems,
      imgAlt: 'Advisory Toughest Problems',
      content: (
        <>
          Our approach <span className="font-bold">ensures</span> your <span className="font-bold">technology investments deliver real business value</span>
          <p className="mt-4">Not on paper, in practice.</p>
        </>
      ),
      borderClass: 'md:border-l-2',
    },
  ];

  const advisoryExamples = [
    {
      project: "Cloud Value Realization (FinOps)",
      description:
        "Matured a bank's FinOps practice by developing an integrated FinOps strategy and engine to automate the identification ($15M+) and distribution of optimization opportunities.",
    },
    {
      project: "Cloud Portfolio Assessment",
      description:
        "Provided a strategic view of a Wealth app portfolio to accelerate its transition to the Cloud – bundled apps to optimize funding and efficiency.",
    },
    {
      project: "Technology Strategy",
      description:
        "Delivered a prioritized strategic roadmap and aligned organizational structure for the IT Division of a major insurer to improve results for its internal clients.",
    },
    {
      project: "Digital Portal Assessment & Strategy",
      description:
        "Assessed the market and completed build/buy analysis for a Digital Portal - created a strategy, business case, and roadmap for the recommended solution.",
    },
    {
      project: "Reliability and Resiliency Roadmap",
      description:
        "Developed a strategy and roadmap to improve resiliency, reduce toil, and achieve $30M annual recurring savings for a major Canadian bank.",
    },
    {
      project: "FinTech Re-architecture",
      description:
        "Rapidly addressed key architectural, stability, security, and resiliency issues for a FinTech company to enable rapid scaling and offer new digital services.",
    },
  ];

  return (
    <main className="text-center flex flex-col gap-6 md:gap-28 px-6 py-6 md:px-12 md:py-12">
      <div className="relative flex justify-center items-center h-[500px]">
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          className="flex flex-col gap-4 md:gap-8 mx-auto px-2.5 sm:px-0 text-center max-w-3xl"
        >
          <motion.h1
            variants={item}
            className="text-6xl font-bold leading-[1.15] text-black sm:leading-[1.15]"
          >
            <span className=" text-purple-700">RAVL</span> Advisory
          </motion.h1>
          <motion.p variants={item} className="text-gray-600 sm:text-xl">
            At <span className="text-purple-700 font-semibold">RAVL</span>, we tackle your toughest challenges with deep expertise in technology strategy, modernization, DevOps, 
            FinOps, and cloud migrations, alongside critical decisions in modern enterprise environments. Our approach is 
            fundamentally bespoke, ensuring solutions uniquely tailored to your needs. Our advisors and engineers excel in 
            <i> raveling</i>—<q>to disentangle and simplify</q>—your most complex projects into manageable successes.
          </motion.p>
          <motion.div variants={item} className="flex flex-wrap justify-center">
            <a
              href="#advisory-offerings"
              className="btn-primary flex items-center justify-center shadow-md text-base w-3/4 md:w-[45%] gap-4"
            >
              Explore Below <ArrowDownIcon className="h-4" />
            </a>
          </motion.div>
        </motion.div>
      </div>

      {/* Offerings Section */}
      <section
        id="advisory-offerings"
        className="grid grid-cols-1 gap-11 xl:px-32 pt-20 md:pt-[88px]"
      >
        <h2 className="text-2xl md:text-4xl font-bold text-gray-900 ">
          Our <span className="text-purple-700">Advisory</span> services bring together top 
          <span className="text-purple-700"> strategic</span> and 
          <span className="text-purple-700"> engineering minds</span> to solve your 
          <span className="text-purple-700"> biggest challenges</span>
        </h2>

        <div className="flex flex-col items-center bg-white">
        <div className="w-full flex flex-col sm:flex-row justify-center items-center bg-gray-100 gap-1 sm:gap-1.5 mb-1 sm:mb-2 p-2 sm:p-4">
          <h2 className="text-sm sm:text-lg font-bold text-purple-700 flex-1 text-center mb-0">
            Top talent with deep FS experience
          </h2>
          <span className="text-2xl sm:text-4xl mb-0">+</span>
          <h2 className="text-sm sm:text-lg font-bold text-purple-700 flex-1 text-center mb-0">
            Advisors and engineers
          </h2>
          <span className="text-2xl sm:text-4xl mb-0">+</span>
          <h2 className="text-sm sm:text-lg font-bold text-purple-700 flex-1 text-center mb-0">
            Senior practitioners on every project
          </h2>
          <span className="text-2xl sm:text-4xl mb-0">=</span>
          <h2 className="text-sm sm:text-lg font-bold text-purple-700 flex-1 text-center mb-0">
            Ability to solve your toughest problems
          </h2>
        </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-1">
            {advisoryOfferings.map((advisoryoffering, index) => (
              <AdvisoryOffering
                key={index}
                imgSrc={advisoryoffering.imgSrc}
                imgAlt={advisoryoffering.imgAlt}
                content={advisoryoffering.content}
                borderClass={advisoryoffering.borderClass}
              />
            ))}
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center gap-8">
        <p className="text-base md:text-xl">
          Connect strategy to delivery. Explore how RAVL Build makes things real.
        </p>
        <Link
          to={"/services/build"}
          className="btn-primary flex items-center justify-center shadow-md text-base w-[15rem] md:w-[20rem]"
        >
          Explore Build
        </Link>
      </div>
      {/* Examples Section */}
      <section className="grid grid-cols-1 gap-11 xl:px-32">
        <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl">
          <span className=" text-purple-700">Examples</span> of our{" "}
          <span className=" text-purple-700">Advisory </span>
          work
        </h2>
        <div>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {advisoryExamples.map((example) => (
              <li key={example.project} className="card text-left">
                <h3 className="text-purple-700 font-bold">{example.project}</h3>
                <p className="text-sm text-gray-600">{example.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </main>
  );
};

export default AdvisoryServices;
