import { motion } from "framer-motion";

// Icons
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.25,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Hero = () => {
  return (
    <section className="relative flex justify-center items-center">
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        className="flex flex-col gap-4 md:gap-8 mx-auto mb-10 mt-12 px-4 text-center max-w-3xl"
      >
        <motion.div
          variants={item}
          className="text-4xl font-bold leading-[1.15] text-black sm:text-6xl sm:leading-[1.15]"
        >
          <h1 className="lg:whitespace-nowrap mb-4 ">
            Strategize. <span className=" text-purple-700">Build.</span>{" "}
          </h1>
          <h1 className="lg:whitespace-nowrap mb-4 ">
            <span className=" text-purple-700">Upskill.</span>{" "} Deliver.
          </h1>
          <h2 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl leading-tight">
            We build sustainable solutions that generate real business value.
          </h2>
        </motion.div>

        <motion.p variants={item} className="text-gray-600 text-lg sm:text-lg">
          <span className="text-purple-700 font-semibold">RAVL</span> bridges
          the gap between business vision and technical execution in Financial
          Services.
          <span className="block mt-2 mb-2 font-semibold">
            We involve advisors and engineers in every engagement.
          </span>
          <span className="block mt-2 mb-2">
            Our engineers ensure we only deliver actionable strategies, and our
            advisors ensure we only build technology that solves real business
            problems.
          </span>
          <span className="block mt-2 mb-2">
            Once the build is complete, our clients can hire a subset of the team 
            to ensure they have the skills required to support the solution and 
            continue the journey.
          </span>
          <span className="block text-purple-700 font-semibold">
            RAVL Builds. Better.
          </span>
        </motion.p>
        {/* <motion.p variants={item} className="text-purple-700 sm:text-xl">
          Build. Better.
        </motion.p> */}
        <motion.div variants={item} className="flex flex-wrap justify-center">
          <a
            href="#RAVL-Offerings"
            className="btn-primary flex items-center justify-center shadow-md text-base w-3/4 md:w-[45%] gap-4"
          >
            Learn More <ArrowRightIcon className="h-4" />
          </a>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
