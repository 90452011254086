import React from "react";
import { v4 as uuidv4 } from "uuid";
// Components
import Card from "./BulletCard";

const BulletCardList = ({ values }) => {
  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center items-stretch">
      {values.map((item) => (
        <li key={uuidv4()} className="flex">
          <Card item={item} className="flex-1" />
        </li>
      ))}
    </ul>
  );
};

export default BulletCardList;
