import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const PrivacyModal = ({ isOpen, closeModal }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 ">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl max-h-full flex flex-col gap-2 sm:gap-4 transform rounded-2xl bg-white p-4 md:p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium lg:text-2xl lg:font-bold leading-6 text-purple-700"
                >
                  RAVL Inc. Privacy Policy
                </Dialog.Title>
                <div className="max-w-full flex flex-col flex-1 mt-2 gap-2 sm:gap-4 overflow-y-auto text-sm text-gray-500 break-words">
                  <p>
                    This Privacy Policy describes how your personal information
                    is collected, used, and shared when you visit or make a
                    purchase from ravl.io (the "Site"). PERSONAL INFORMATION WE
                    COLLECT When you visit the Site, we automatically collect
                    certain information about your device, including information
                    about your web browser, IP address, time zone, and some of
                    the cookies that are installed on your device. Additionally,
                    as you browse the Site, we collect information about the
                    individual web pages or products that you view, what
                    websites or search terms referred you to the Site, and
                    information about how you interact with the Site. We refer
                    to this automatically-collected information as "Device
                    Information."{" "}
                  </p>
                  <p>
                    We collect Device Information using the following
                    technologies: - "Cookies" are data files that are placed on
                    your device or computer and often include an anonymous
                    unique identifier. For more information about cookies, and
                    how to disable cookies, visit
                    http://www.allaboutcookies.org. - "Log files" track actions
                    occurring on the Site, and collect data including your IP
                    address, browser type, Internet service provider,
                    referring/exit pages, and date/time stamps. - "Web beacons,"
                    "tags," and "pixels" are electronic files used to record
                    information about how you browse the Site. Additionally when
                    you make a purchase or attempt to make a purchase through
                    the Site, we collect certain information from you, including
                    your name, billing address, shipping address, payment
                    information (including credit card numbers), email address,
                    and phone number. We refer to this information as "Order
                    Information."{" "}
                  </p>
                  <p>
                    When we talk about "Personal Information" in this Privacy
                    Policy, we are talking both about Device Information and
                    Order Information. HOW DO WE USE YOUR PERSONAL INFORMATION?
                    We use the Order Information that we collect generally to
                    fulfill any orders placed through the Site (including
                    processing your payment information, arranging for shipping,
                    and providing you with invoices and/or order confirmations).
                    Additionally, we use this Order Information to: Communicate
                    with you; Screen our orders for potential risk or fraud; and
                    When in line with the preferences you have shared with us,
                    provide you with information or advertising relating to our
                    products or services.
                  </p>
                  <p>
                    We use the Device Information that we collect to help us
                    screen for potential risk and fraud (in particular, your IP
                    address), and more generally to improve and optimize our
                    Site (for example, by generating analytics about how our
                    customers browse and interact with the Site, and to assess
                    the success of our marketing and advertising campaigns).
                    SHARING YOUR PERSONAL INFORMATION We share your Personal
                    Information with third parties to help us use your Personal
                    Information, as described above. For example, we use Shopify
                    to power our online store--you can read more about how
                    Shopify uses your Personal Information here:
                    https://www.shopify.com/legal/privacy. We also use Google
                    Analytics to help us understand how our customers use the
                    Site--you can read more about how Google uses your Personal
                    Information here:
                    https://www.google.com/intl/en/policies/privacy/.
                  </p>
                  <p>
                    You can also opt-out of Google Analytics here:
                    https://tools.google.com/dlpage/gaoptout. Finally, we may
                    also share your Personal Information to comply with
                    applicable laws and regulations, to respond to a subpoena,
                    search warrant or other lawful request for information we
                    receive, or to otherwise protect our rights. BEHAVIOURAL
                    ADVERTISING As described above, we use your Personal
                    Information to provide you with targeted advertisements or
                    marketing communications we believe may be of interest to
                    you. For more information about how targeted advertising
                    works, you can visit the Network Advertising Initiative's
                    ("NAI") educational page at
                    http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                    Additionally, you can opt out of some of these services by
                    visiting the Digital Advertising Alliance's opt-out portal
                    at: http://optout.aboutads.info/. DO NOT TRACK Please note
                    that we do not alter our Site's data collection and use
                    practices when we see a Do Not Track signal from your
                    browser.
                  </p>
                  <p>
                    YOUR RIGHTS If you are a European resident, you have the
                    right to access personal information we hold about you and
                    to ask that your personal information be corrected, updated,
                    or deleted. If you would like to exercise this right, please
                    contact us through the contact information below.
                    Additionally, if you are a European resident we note that we
                    are processing your information in order to fulfill
                    contracts we might have with you (for example if you make an
                    order through the Site), or otherwise to pursue our
                    legitimate business interests listed above. Additionally,
                    please note that your information will be transferred
                    outside of Europe, including to Canada and the United
                    States. DATA RETENTION When you place an order through the
                    Site, we will maintain your Order Information for our
                    records unless and until you ask us to delete this
                    information.
                  </p>
                  <p>
                    MINORS The Site is not intended for individuals under the
                    age of 18. CHANGES We may update this privacy policy from
                    time to time in order to reflect, for example, changes to
                    our practices or for other operational, legal or regulatory
                    reasons. CONTACT US For more information about our privacy
                    practices, if you have questions, or if you would like to
                    make a complaint, please contact us by e-mail at
                    info@ravl.io or by mail using the details provided below: 6
                    Annesley Ave, East York, ON, M4G 2T6, Canada
                  </p>
                </div>

                <div className="mt-2">
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PrivacyModal;
