import { UserIcon } from "@heroicons/react/24/solid";

const BreakdownDetails = ({ item }) => (
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
    <div className="card flex flex-col gap-2">
      <h6 className="font-bold text-2xl text-purple-700 mb-2">How it works</h6>
      {item.works.map((work, index) => (
        <div
          key={index}
          className="flex items-center gap-x-4 text-sm leading-6"
        >
          <div className="flex h-12 w-12 flex-none items-center justify-center">
            <work.icon
              className="h-8 w-8 text-gray-600 group-hover:text-purple-700"
              aria-hidden="true"
            />
          </div>
          <div className="flex-auto text-left">
            <h6 className="font-bold text-base text-purple-700">
              {work.label}
            </h6>
            <p className="text-sm text-gray-600">{work.description}</p>
          </div>
        </div>
      ))}
    </div>

    <div className="card flex-auto">
      <h6 className="font-bold text-2xl text-purple-700 mb-2">
        Team Structure
      </h6>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-left">
        <div className="flex flex-col gap-4">
          <h4 className="font-bold">Pod Breakdown</h4>
          {Object.values(item["Pod Breakdown"]).map((level, index) => (
            <div key={index} className="grid grid-cols-3 gap-4">
              <h4 className="text-left text-sm text-gray-600">{level.title}</h4>
              <div className="col-span-2 flex gap-2">
                {[...Array(level.num)].map((e, i) => (
                  <UserIcon
                    key={i}
                    className={`h-6 w-6 ${
                      level.title !== "Your Engineers"
                        ? "text-purple-700"
                        : "text-red-700"
                    } ${
                      level.title !== "Your Future Engineers"
                        ? "text-purple-700"
                        : "text-teal-400"
                    }`}
                    aria-hidden="true"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-4">
          <h4 className="font-bold">Enablement Team</h4>
          {Object.values(item["Enablement Team"]).map((level, index) => (
            <div key={index} className="grid grid-cols-3 gap-4">
              <h4 className="text-left text-sm text-gray-600">{level.title}</h4>
              <div className="flex gap-2">
                {[...Array(level.num)].map((e, i) => (
                  <UserIcon
                    key={i}
                    className={`h-6 w-6 ${
                      level.title !== "Your Engineers"
                        ? "text-purple-700"
                        : "text-red-700"
                    } ${
                      level.title !== "Your Future Engineers"
                        ? "text-purple-700"
                        : "text-teal-400"
                    }`}
                    aria-hidden="true"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default BreakdownDetails;
