import React from "react";
import Hero from "../components/sections/Hero.jsx";
import TechnicalExpertise from "../components/sections/TechnicalExpertise";
import RavlOfferings from "../components/sections/RavlOfferings";

const Home = () => {
  return (
    <div className="container mx-auto">
      <Hero />
      <TechnicalExpertise />
      <RavlOfferings />
    </div>
  );
};

export default Home;
