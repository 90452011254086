import { useRef, useState } from "react";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

// Icons
import {
  ComputerDesktopIcon,
  CloudIcon,
  ServerStackIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

const buildOfferings = [
  {
    title: "App Modernization & /nDevelopment",
    icon: ComputerDesktopIcon,
    front: {
      description:
        "Gain a competitive edge through expert software engineering, timely delivery, and leveraging our Cloud Native development capability.",
    },
    back: {
      description:
        "Our technical and engineering excellence elevates everything we build for you. We commit to quality and timely delivery from start to finish.",
      bullets: [
        "Web Applications",
        "Mobile Applications",
        "API & Event Driven Platforms",
        "Cloud Native Development",
      ],
    },
  },
  {
    title: "Cloud Platforms & /nInfrastructure",
    icon: CloudIcon,
    front: {
      description:
        "Boost Cloud adoption in complex multi-cloud environments without sacrificing security and trust while optimizing your costs.",
    },
    back: {
      description:
        "Our adept Cloud Engineers excel in regulated multi-cloud settings, creating secure, efficient solutions with a focus on safety, compliance, and optimization.",
      bullets: [
        "Application Migration",
        "Containers & Kubernetes",
        "Internal FinOps Service",
        "Infrastructure as Code",
        "Hybrid Monitoring & Observability",
      ],
    },
  },
  {
    title: "AI & Data /nSolutions",
    icon: Cog6ToothIcon,
    front: {
      description:
        "Align your data and AI strategies with immediate objectives while fostering continuous innovation and expansion",
    },
    back: {
      description:
        "We architect robust data-led solutions, ready for future growth and integration. We unlock the transformative power of AI by fueling data without putting it at risk. We can help you explore and identify AI use cases.",
      bullets: [
        "Artificial Intelligence",
        "Data Platform Engineering",
        "ETL & Data Lake Engineering",
        "Governance Implementation",
        "Data Migration Strategies",
      ],
    },
  },
  {
    title: "DevSecOps /nEngineering",
    icon: ServerStackIcon,
    front: {
      description:
        "Leverage DevSecOps practices to unify software, cloud, and data considerations and foster a culture of continuous innovation, collaboration and security.",
    },
    back: {
      description:
        "Integrating development, security, and operations streamlines your delivery lifecycle, swiftly addressing operational challenges and enabling teams to prioritize their core objectives.",
      bullets: [
        "Infrastructure as Code",
        "DevSecOps Evaluations",
        "Environment Optimization",
        "Continuous Delivery & Deployment",
        "Comprehensive Automation",
      ],
    },
  },
];

const cardVariants = {
  selected: {
    rotateY: 180,
    transition: { duration: 0.1 },
  },
  notSelected: () => ({
    rotateY: 0,
    transition: { duration: 0.1 },
  }),
};

const BuildOfferings = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const containerRef = useRef();
  const cardRefs = useRef(new Array([]));

  const selectCard = (e, card) => {
    setSelectedCard(selectedCard === card ? null : card);
  };

  const handleMouseLeave = () => {
    setSelectedCard(null);
  };

  return (
    <section
      id="What-we-build"
      ref={containerRef}
      onMouseLeave={handleMouseLeave}
      className="flex flex-col gap-8  pt-20 md:pt-[88px]"
    >
      <div className="flex flex-col gap-6">
        <h2 className="text-4xl font-bold leading-[1.15] text-black sm:text-6xl sm:leading-[1.15]">
          <span className=" text-purple-700">What</span> we like to{" "}
          <span className=" text-purple-700">Build</span>
        </h2>
        <div className="flex flex-row flex-wrap xl:flex-nowrap justify-center gap-4">
          {buildOfferings.map((buildOffering) => (
            <motion.div
              key={buildOffering.title}
              className="flip-card flip-card-inner h-110 w-full md:w-[48%] xl:w-[32%] cursor-pointer rounded-lg"
              ref={(el) => cardRefs.current.push(el)}
              onClick={(e) => selectCard(e, buildOffering)}
              initial={false}
              variants={cardVariants}
              animate={
                selectedCard === buildOffering ? "selected" : "notSelected"
              }
              custom={selectedCard ? selectedCard - buildOffering : 0}
            >
              <div className="flip-card-front card w-full h-full text-gray-600 flex flex-col items-center justify-between">
                <div className="flex flex-col pt-2 items-center gap-4">
                  <div className="mx-auto flex h-28 w-28 flex-shrink-0 items-center justify-center rounded-full bg-purple-100  border-purple-600 border-4">
                    <buildOffering.icon
                      className="h-14 w-14 text-purple-600"
                      aria-hidden="true"
                    />
                  </div>
                  <h4 className="text-xl font-bold">
                    {buildOffering.title.split("/n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </h4>
                </div>
                <p className="text-sm lg:text-base">
                  {buildOffering.front.description}
                </p>
                <div className="w-full flex flex-col items-center gap-4">
                  <div className="w-full border-t-2 border-purple-600 p-2 pb-0">
                    <p className="cursor-pointer">Explore {">"}</p>
                  </div>
                </div>
              </div>
              <div className="flip-card-back card border-none w-full h-full text-sm flex flex-col justify-between">
                <div className="flex flex-col gap-4 text-left">
                  <h4 className="text-purple-700 text-center text-xl font-bold">
                    {buildOffering.title.split("/n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </h4>
                  <p>{buildOffering.back.description}</p>
                  <div>
                    <p className="text-lg font-bold">We can help you with:</p>
                    {buildOffering.back.bullets && (
                      <ul className="list-disc pl-5">
                        {buildOffering.back.bullets.map((bullet) => (
                          <li key={uuidv4()}>{bullet}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="border-t-2 border-purple-600 p-2 pb-0 justify-self-end">
                  <p className="cursor-pointer">Back {">"}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BuildOfferings;
