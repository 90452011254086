import React from "react";

// Icons
import {
  HomeIcon,
  HeartIcon,
  AcademicCapIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";

// Components
import CardList from "../components/common/BulletCard/BulletCardList";

const perks = [
  {
    label: "Leave a dent in the universe",
    description: "You make an impact, while helping RAVL make an impact.",
    icon: GlobeAltIcon,
  },
  {
    label: "Relax and recover",
    description: "Flexible paid time off",
    icon: SparklesIcon,
  },
  {
    label: "Growth opportunities",
    description: "We are dedicated to lifelong learning.",
    icon: AcademicCapIcon,
  },
  {
    label: "Apart, yet together",
    description: "Work from anywhere within Canada",
    icon: HomeIcon,
  },
  {
    label: "Healthy, wealthy, and wise",
    description: "Health benefits and mental health support.",
    icon: HeartIcon,
  },
  {
    label: "Own your success",
    description: "Generous compensation and bonus options.",
    icon: CurrencyDollarIcon,
  },
];

const quotes = [
  {
    name: "Justin Chapman",
    position: "Former BuildX apprentice and current software developer at a big five bank",
    quote:
      "What sets the RAVL apprenticeship apart is that you’re set up for success from day one. It’s hard to create a talent pipeline in this industry—it’s resource-intensive, and things are changing so fast that you're always training along the way. So when companies can quickly hire someone who is not only well-trained but also knowledgeable about their systems and immersed in their culture, that’s a huge benefit.",
  },
  {
    name: "June Coffey",
    position: "RAVL recruiter and C++ developer",
    quote:
      "The RAVL team has so much incredible experience, and they have this humility and caring about them. It shows from the first conversation to how they deal with their employees to the company culture they’re building.",
  },
  {
    name: "Max Wanless",
    position: "Former BuildX apprentice and current software developer at RAVL",
    quote:
      "Not only did I have the opportunity to contribute meaningfully to the project we were working on, but the apprenticeship also placed a strong emphasis on professional learning and development, ensuring that RAVL's clients receive employees who are fully prepared for the job.",
  },
];

const Careers = () => {
  return (
    <>
      <section className="px-6 py-6 text-center md:px-12 md:py-12">
        <div className="container grid grid-cols-1 gap-11 mx-auto xl:px-32">
          <div className="flex flex-col items-center gap-4 md:gap-8 text-center max-w-3xl mx-auto">
            <h2 className="text-4xl font-bold leading-[1.15] text-black sm:text-6xl sm:leading-[1.15]">
              Want to <span className="text-purple-700">build</span> amazing
              tech and have <span className="text-purple-700">fun </span>
              doing it?
            </h2>
            <p className="text-gray-600 sm:text-xl">
              We are always looking for talented and ambitious engineers to join
              our team. At{" "}
              <span className=" text-purple-700 font-bold">RAVL</span>, our
              emphasis is on
              <span className=" text-purple-700 font-bold">
                {" "}
                constant growth{" "}
              </span>
              and
              <span className="text-purple-700 font-bold">
                {" "}
                pushing boundaries
              </span>
              . If you like leaving your comfort zone and want to become the
              best technologist you can be, contact us now to apply!
            </p>{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://ravljobs.applytojobs.ca/"
              className="btn-primary"
              aria-label="View job openings"
            >
              View Openings
            </a>
          </div>

          {/* Perks Card List */}
          <CardList values={perks} />
        </div>
      </section>

      <section className="px-6 py-4 text-center md:px-12 md:py-8">
        <div className="container grid grid-cols-1 gap-11 mx-auto xl:px-32">
          <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl">
            What do <span className="text-purple-700">RAVL</span>r's have to
            say?
          </h2>
          <div className="flex flex-col  w-full gap-4 lg:gap-6 ">
  {quotes.map((quote, index) => (
    <div
      className={`card text-left max-w-3xl self-center ${index % 2 ? "md:self-end" : "md:self-start"
        }`}
      key={quote.name}
    >
      <div className="grid grid-cols-1 gap-4">
        <svg
          aria-hidden="true"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="20"
        >
          <path
            className="fill-purple-500"
            d="M6.36 20c3.437.0 5.703-2.42 5.703-5.196.0-2.99-1.9-4.91-5.19-4.91-.658.0-1.17.142-1.536.355.146-3.274 3.217-5.48 5.995-5.907V0C6.726.427.0 3.63.0 13.167.0 16.94 2.486 20 6.36 20zm14.604.0c3.436.0 5.703-2.42 5.703-5.196.0-2.99-1.901-4.91-5.191-4.91-.658.0-1.17.142-1.536.355.147-3.274 3.217-5.48 5.996-5.907V0c-4.607.427-11.333 3.63-11.333 13.167.0 3.773 2.486 6.833 6.36 6.833z"
          ></path>
        </svg>
        <p className="text-base text-gray-600">{quote.quote}</p>
        <div className="flex justify-end">
        <svg
          aria-hidden="true"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="20"
          viewBox="0 0 27 20"
        >
          <path
            className="fill-purple-500"
            transform="scale(-1, -1) translate(-27, -20)"
            d="M6.36 20c3.437.0 5.703-2.42 5.703-5.196.0-2.99-1.9-4.91-5.19-4.91-.658.0-1.17.142-1.536.355.146-3.274 3.217-5.48 5.995-5.907V0C6.726.427.0 3.63.0 13.167.0 16.94 2.486 20 6.36 20zm14.604.0c3.436.0 5.703-2.42 5.703-5.196.0-2.99-1.901-4.91-5.191-4.91-.658.0-1.17.142-1.536.355.147-3.274 3.217-5.48 5.996-5.907V0c-4.607.427-11.333 3.63-11.333 13.167.0 3.773 2.486 6.833 6.36 6.833z"
          ></path>
        </svg>
        </div>
        <p className="italic text-base text-purple-600">
          {quote.position}
        </p>
      </div>
    </div>
  ))}
</div>

        </div>
      </section>
    </>
  );
};

export default Careers;
