import caseStudyOne from "./assets/images/casestudies/cs-img-one.jpg";
import photoOne from "./assets/images/casestudies/cs-banner-img-one.jpg";
import caseStudyTwo from "./assets/images/casestudies/cs-img-two.jpg";
import photoTwo from "./assets/images/casestudies/cs-banner-img-two.jpg";
import caseStudyThree from "./assets/images/casestudies/cs-img-three.jpg";
import photoThree from "./assets/images/casestudies/cs-banner-img-three.jpg";
import caseStudyFour from "./assets/images/casestudies/cs-img-four.jpg";
import photoFour from "./assets/images/casestudies/cs-banner-img-four.jpg";

import {
  ComputerDesktopIcon,
  Square3Stack3DIcon,
  CloudArrowDownIcon,
  ChartPieIcon,
  ShieldCheckIcon,
  ArrowsPointingInIcon,
} from "@heroicons/react/24/outline";

//
// image:,
// caseStudyImage:,
// section: []
// caseStudyName: "",
// title: "",
// overviewValues: [],
// resultValues: [],
// engagementSnap: "",
// clientChallenges:[],
// whatWeDid: []

export const caseStudies = [
  {
    image: caseStudyOne,
    caseStudyImage: photoOne,
    section: ["aws", "build"],
    caseStudyName: "aws-insurtech",
    title: "AWS: Modernizing and Securing an Insurtechs Data Lake",
    overviewValues: [
      {
        icon: ComputerDesktopIcon,
        label: "Infrastructure-as-Code",
      },
      {
        icon: ArrowsPointingInIcon,
        label: "DevSecOps & FinOps",
      },
      {
        icon: Square3Stack3DIcon,
        label: "Data Engineering & Architecture",
      },
      {
        icon: ShieldCheckIcon,
        label: "Networking and Security Hardening",
      },
      {
        icon: ChartPieIcon,
        label: "Governance & Project Management",
      },
      {
        icon: CloudArrowDownIcon,
        label: "AWS Enablement",
      },
    ],
    resultValues: [
      {
        label: "100%",
        description:
          "Of the AWS infrastructure codified in Terraform and Deployed via GitHub Actions.",
      },
      {
        label: "GO-LIVE",
        description:
          "The client was able to hit their target production date and ingest data.",
      },
      {
        label: "25%",
        description:
          "Reduction in AWS run costs reinvested into new services and capabilities.",
      },
      {
        label: "12+",
        description:
          "Significant security vulnerabilities remediated and codified.",
      },
    ],
    engagementSnap:
      "The client is a not-for-profit Insurtech, a national organization supporting Canadian property and casualty (P&C) insurers. Their primary function is aggregating disparate data from P&C insurers into a data lake. This data is then processed to extract intelligent alerts and actions, allowing insurers to combat fraudulent activities collaboratively rather than operating in isolated silos.",
    clientChallenges: [
      {
        key: "Time Constraints & Existing Implementation Gaps",
        value:
          "The client had a tight go-live deadline. Their existing AWS data lake implementation contained security gaps, was heavily over-engineered and contained documentation inconsistencies.",
      },
      {
        key: "Cloud Engineering Practices",
        value:
          "The client faced challenges with AWS service usage, external data exchange security, and Infrastructure-as-Code (IaC) implementation.",
      },
      {
        key: "Missing Pipelines",
        value:
          "No infrastructure and data pipelines existed, resulting in manual implementations within the AWS console, leading to inconsistencies and inefficiencies.",
      },
      {
        key: "Significant Cost Overrun",
        value:
          "The client's AWS costs were significantly higher than necessary and rising.",
      },
    ],
    whatWeDid: [
      {
        key: "Infrastructure-as-Code Implementation",
        value:
          "We rebuilt infrastructure and data workloads using Terraform and GitHub, ensuring consistent, reproducible, and auditable environments.",
      },
      {
        key: "DevSecOps and Data Pipeline Engineering",
        value:
          "Implemented new infrastructure and data pipelines using a GitOps approach via GitHub, automating deployments with GitHub Actions and Terraform Cloud (TFC) and enhancing security with Prisma Cloud's static code analysis integration with TFC's Sentinel policies, ensuring a streamlined, secure, and compliant deployment process.",
      },
      {
        key: "Data Engineering",
        value:
          "Refined Data Lake workloads using AWS Glue for efficient ETL (Extract, Transform, Load) processes, using Crawlers to discover and catalogue data and Data Catalog for maintaining metadata. AWS Lambda functions enabled custom ETL operations, ensuring efficient data processing and management.",
      },
      {
        key: "Networking Stack Overhaul",
        value:
          "The AWS networking stack was simplified for better security, industry alignment, and cost reduction. AWS Transit Gateway was used for a centralized model, alongside AWS Network Firewall for traffic inspection and VPC Endpoints to minimize public internet exposure, improving data privacy and security. Over-provisioned CIDRs were fixed with Amazon VPC IP Address Manager (IPAM) to reduce operational overhead.",
      },
      {
        key: "Security Remediation",
        value:
          "We addressed and remediated dozens of security vulnerabilities using a combination of security best practices, native AWS services and third-party solutions, such as AWS Security Hub, Palo Alto Prisma Cloud and TrendMicro integrations, securing the environment against potential threats.",
      },
      {
        key: "FinOps Cost Optimization",
        value:
          "We significantly reduced the client's operational costs by remediating over-engineered implementations and focusing on simplified yet robust, essential builds.",
      },
    ],
  },
  {
    image: caseStudyTwo,
    caseStudyImage: photoTwo,
    section: ["azure", "build"],
    caseStudyName: "finops-bank",
    title: "FinOps: Optimizing Cloud Spending for a Major Canadian Bank",
    overviewValues: [
      {
        icon: ComputerDesktopIcon,
        label: "Azure",
      },
      {
        icon: ArrowsPointingInIcon,
        label: "GCP",
      },
      {
        icon: Square3Stack3DIcon,
        label: "Infrastructure-as-Code",
      },
      {
        icon: ShieldCheckIcon,
        label: "FinOps & DevSecOps",
      },
      {
        icon: ChartPieIcon,
        label: "Governance & Project Management",
      },
      {
        icon: CloudArrowDownIcon,
        label: "GitHub Actions",
      },
    ],
    resultValues: [
      {
        label: "10M+",
        description: "In identified savings across Cloud platforms.",
      },
      {
        label: "2M",
        description: "In savings captured during our work.",
      },
      {
        label: "10+",
        description: "data sources aggregated together.",
      },
      {
        label: "1M",
        description:
          "in new levers identified via the RAVL Cost Optimization Engine.",
      },
    ],
    engagementSnap:
      "The client, one of the big five banks of Canada, faced a significant challenge with their cloud operating costs. With a large workload migration to the Cloud on the horizon, they sought to optimize spending and better manage their costs.",
    clientChallenges: [
      {
        key: "Increased Cloud Spend",
        value:
          "Substantial growth in cloud footprint led to increased spending.",
      },
      {
        key: "Unnecessary Waste",
        value:
          "Expansion in development velocity resulted in increased cloud waste.",
      },
      {
        key: "Infrequent Environment Clean-up",
        value:
          "No systematic approach for identifying and remedying misconfigured resources.",
      },
      {
        key: "Isolated Approaches",
        value: "Siloed efforts without a cohesive FinOps strategy.",
      },
      {
        key: "Alignment on Spend",
        value:
          "Addressed the complexity of aggregating spend into a transparent shared service.",
      },
      {
        key: "Technical Integration",
        value:
          "Overcoming challenges in connecting data from multiple cloud vendors and internal cost structures.",
      },
      {
        key: "Organizational Change Management",
        value:
          "Implemented change management to motivate engineering teams to adopt cloud levers.",
      },
    ],
    whatWeDid: [
      {
        key: "FinOps and Cloud Posture Assessment",
        value:
          "Conducted an in-depth assessment of the client's FinOps and cloud posture.",
      },
      {
        key: "Spending Trends Analysis",
        value: "Analyzed year-over-year cloud spending trends.",
      },
      {
        key: "Cloud Environment Audit",
        value: "Examined cloud environments for gaps in best practices.",
      },
      {
        key: "FinOps Strategy Overhaul",
        value:
          "Overhauled their FinOps strategy, defining roles for a central FinOps team to promote a proactive approach",
      },
      {
        key: "Optimization Engine Deployment",
        value:
          "Deployed the RAVL FinOps Optimization Engine in Azure to collect and analyze cloud-related metadata.",
      },
      {
        key: "Custom Recommendation Development",
        value:
          "Developed custom recommendation levers for high-value use cases and aligned FinOps with business outcomes.",
      },
    ],
  },
  {
    image: caseStudyThree,
    caseStudyImage: photoThree,
    section: ["azure", "build"],
    caseStudyName: "bank-modernization",
    title:
      "App Modernization: Modernizing a Retail Banking Platform for a Major Canadian Bank",
    overviewValues: [
      {
        icon: ComputerDesktopIcon,
        label: "Architecture",
      },
      {
        icon: ArrowsPointingInIcon,
        label: "Micro Frontend Development",
      },
      {
        icon: Square3Stack3DIcon,
        label: "Program Delivery",
      },
      {
        icon: ShieldCheckIcon,
        label: "Azure",
      },
      {
        icon: ChartPieIcon,
        label: "DevSecOps Pipelines",
      },
      {
        icon: CloudArrowDownIcon,
        label: "Cloud Modernization",
      },
      {
        icon: CloudArrowDownIcon,
        label: "iOS Development",
      },
    ],
    resultValues: [
      {
        label: "10+",
        description: "Product pods using our new micro-frontend architecture.",
      },
      {
        label: "25+",
        description: "New micro-frontends.",
      },
      {
        label: "87",
        description: "New components.",
      },
      {
        label: "36",
        description: "New component sets.",
      },
      {
        label: "7 MM+",
        description: "Active Canadians using the new platform.",
      },
      {
        label: "2",
        description: "Software engineers transitioned to the bank via BuildX.",
      },
    ],
    engagementSnap:
      "The client is a Big 5 Canadian bank offering personal and business banking services. They have a widely-used banking app for mobile and web users. They faced an escalating backlog of software updates, significant currency updates, and a prior, unfinished effort to revitalize their outdated user interface.",

    clientChallenges: [
      {
        key: "Legacy Frontend",
        value:
          "After layering two decades of enhancements, the client's frontend technology was incompatible with contemporary micro-frontend architecture.",
      },
      {
        key: "Development Constraints",
        value:
          "The legacy nature of the application required extensive refactoring and regression testing for any change, causing an unsustainable dependency across multiple teams and limiting new feature velocity.",
      },
      {
        key: "Inconsistencies Across Mobile and Web",
        value:
          "A longstanding approach to separating mobile and web platform development and release created feature variance across platforms, leading to an inconsistent user experience.",
      },
      {
        key: "Hybrid Cloud Transition",
        value:
          "The client transitioned from on-premise to cloud services, adding further complexity to the deployment and modernization requirements of the platform.",
      },
      {
        key: "Aesthetic Modernization Need",
        value:
          "The client's UI components needed an overhaul to align with modern UI design and usability requirements and expectations.",
      },
    ],
    whatWeDid: [
      {
        key: "Micro Frontend Architecture Implementation",
        value:
          "We provided comprehensive architecture support, designing new Micro Frontend infrastructure for independent deployments. This involved establishing DevSecOps to build pipeline processes to Azure, enhancing application performance, and integrating across cloud and on-premise environments for seamless data synchronization.",
      },
      {
        key: "Responsive Web Development",
        value:
          "We refreshed the WebApp with our new Micro Frontend, enabling shared feature development across multiple platforms (Web, iOS, and Android). This approach allowed independent team contributions and dynamic assembly into a unified, high-performance banking client application.",
      },
      {
        key: "Feature Development and Rollout",
        value:
          "For the initial launch, we created a simplified questionnaire workflow for internal teams showcasing the capabilities of our newly developed components. This approach served as a model for modern UI development and a practical demonstration ('dogfooding') of our infrastructure solutions.",
      },
      {
        key: "iOS UI Components Upgrade",
        value:
          "We assisted in revamping the iOS component library to align with the client's new design language, focusing on creating reusable and adaptable components.",
      },
      {
        key: "Building Client Capability through BuildX",
        value:
          "Our senior developers mentored new apprentices, instilling best practices in modern software development. These apprentices actively participated in developing the new infrastructure and features, gaining hands-on experience and transferring to our client as full-time developers after completing our work.",
      },
    ],
  },
  {
    image: caseStudyFour,
    caseStudyImage: photoFour,
    section: ["build"],
    caseStudyName: "fintech-credit-platform",
    title:
      "App Modernization: Stabilizing and Professionalizing a FinTech Credit Platform",
    overviewValues: [
      {
        icon: ComputerDesktopIcon,
        label: "Java and React",
      },
      {
        icon: ArrowsPointingInIcon,
        label: ".NET",
      },
      {
        icon: Square3Stack3DIcon,
        label: "Azure Enablement",
      },
      {
        icon: ShieldCheckIcon,
        label: "DevSecOps & FinOps",
      },
      {
        icon: ChartPieIcon,
        label: "Operating Model & Governance",
      },
      {
        icon: CloudArrowDownIcon,
        label: "Advisory",
      },
    ],
    resultValues: [
      {
        label: "12+",
        description: "High-impact Azure security findings remediated.",
      },
      {
        label: "ROLLOUT",
        description: "Of new operating model and SDLC.",
      },
      {
        label: "5",
        description: "Severity 1 defects remediated.",
      },
      {
        label: "10,000+",
        description: "Per month reduced in Azure costs.",
      },
    ],
    engagementSnap:
      "The client faced significant challenges with their MVP, including architectural, stability, security, and resiliency issues, negatively impacting customer experience. With a rapidly growing volume of customers coupled with limited engineering capability, immediate intervention was needed.",

    clientChallenges: [
      {
        key: "Architectural and Operational Challenges",
        value:
          "The MVP was plagued with structural and functional deficiencies, critically affecting its performance, security, and reliability.",
      },
      {
        key: "Organizational Technology Limitations",
        value:
          "There were limited processes, structure, toolsets, and governance within the technology organization, impeding scale and creating significant technical debt.",
      },
      {
        key: "Rapid Customer Onboarding",
        value:
          "The increasing number of new customers exacerbated existing issues, demanding urgent remediation.",
      },
      {
        key: "Cloud Infrastructure Gaps",
        value:
          "The implementation within Azure was executed through 'ClickOps,' limiting the ability of the team to manage configurations, understand drift from the expected state, and cause churn when adding new services or implementing new designs.",
      },
    ],
    whatWeDid: [
      {
        key: "Stabilize the Platform",
        value:
          "We identified and prioritized critical issues impacting stability and established the processes and structure needed to begin remediation activities.",
      },
      {
        key: "Enable the Platform",
        value:
          "We developed a comprehensive technology strategy and roadmap to support current and future needs and established a prioritized Platform backlog, identifying necessary tactics and resources for execution.",
      },
      {
        key: "Operating Model and Technology Standards Refresh",
        value:
          "We redesigned the Engineering organization (structure, roles, teams), implemented governance and established an SDLC, defined new development standards and practices, and developed a DevSecOps technology toolchain.",
      },
    ],
  },
];
