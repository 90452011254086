import { caseStudies } from "./data";

export const filterCaseStudiesBySection = (sectionKey) => {
  const lowerCaseSectionKey = sectionKey.toLowerCase();
  return caseStudies.filter((caseStudy) =>
    caseStudy.section.some(
      (sectionItem) => sectionItem.toLowerCase() === lowerCaseSectionKey
    )
  );
};
