import React from "react";
import {
  ComputerDesktopIcon,
  Square3Stack3DIcon,
  ArrowsPointingOutIcon,
  Cog6ToothIcon,
  CloudArrowDownIcon
} from "@heroicons/react/24/outline";
import BulletCardList from "../common/BulletCard/BulletCardList";

const TechnicalExpertise = () => {
  const cardData = [
    {
      icon: ComputerDesktopIcon,
      label: "Full-Stack Digital Product Development",
      description:
        "We manage every digital product stage, delivering tailored, scalable solutions with diverse tech expertise."
    },
    {
      icon: Square3Stack3DIcon,
      label: "Core Systems Modernization",
      description: "We transform legacy systems into agile platforms using the latest technologies."
    },
    {
      icon: ArrowsPointingOutIcon,
      label: "Microservices & APIs",
      description:
        "Our microservices approach and APIs ensure system resilience, scalability, and seamless integration."
    },
    {
      icon: Cog6ToothIcon,
      label: "DevSecOps & Developer Experience",
      description:
        "Security is integral to our development, paired with an optimized developer experience for swift market delivery."
    },
    {
      icon: CloudArrowDownIcon,
      label: "Cloud Platforms & Tooling",
      description: "We harness top cloud platforms, offering robust solutions and streamlining cloud processes."
    },
    {
      icon: ComputerDesktopIcon,
      label: "Site Reliability Engineering",
      description: "With a focus on uptime, we proactively monitor and optimize platforms for maximum reliability."
    }
  ];

  return (
    <section className="flex flex-col items-center p-4 md:p-16">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold text-gray-900 ">
          Our strength lies in our deep <span className="text-purple-800">expertise.</span>
        </h2>
      </div>
      <div className="group flex items-center gap-x-6 text-sm leading-6">
        <BulletCardList values={cardData} />
      </div>
    </section>
  );
};
export default TechnicalExpertise;
