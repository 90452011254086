import { Link } from "react-router-dom";

const IconLink = ({ icon, text, link, className }) => (
  <Link
    to={link}
    className={`btn-primary mt-12 flex items-center justify-center shadow-md text-base w-3/4 md:w-60 gap-4 ${className}`}
    //{className} Includes any additional classes passed via the `className` prop.
  >
    {text}
    {icon && <span className="ml-2">{icon}</span>}
    {/* Conditionally renders the icon if it is provided. Adds a margin-left of 2 to separate the icon from the text. */}
  </Link>
);

export default IconLink;
