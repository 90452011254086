import React, { useState, useEffect } from "react";
import { filterCaseStudiesBySection } from "../utils";
import partnershipDigitalAppImg from "../assets/images/partnership-azure-digital-and-app-innovation.png";
import partnershipDataAIImg from "../assets/images/partnership-azure-data-and-ai.png";
import partnershipMarketPlace from "../assets/images/partnership-microsoft-azure-marketplace.png";
import BulletCardList from "../components/common/BulletCard/BulletCardList";
import Carousel from "../components/sections/Carousel";
import {
  ComputerDesktopIcon,
  Square3Stack3DIcon,
  ArrowsPointingOutIcon,
  Cog6ToothIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";

const Azure = () => {
  const [selectedCaseStudies, setSelectedCaseStudies] = useState([]);
  const services = [
    {
      icon: ComputerDesktopIcon,
      label: "Azure Cloud Migration Services",
      description:
        "Don’t let the complexity of the migration process intimidate you! With our skilled Azure specialists, we ensure a seamless, secure, and efficient migration to Azure platform with minimal operational disruptions.",
    },
    {
      icon: Square3Stack3DIcon,
      label: "Azure Infrastructure Solutions",
      description:
        "We encompass everything, from the design and architecture to the deployment of your infrastructure on Azure. We focus on leveraging the scalability and flexibility of Azure to enhance the efficiency of your business operations.",
    },
    {
      icon: ArrowsPointingOutIcon,
      label: "Azure DevOps Solutions",
      description:
        "Our Azure DevOps services aim to drive productivity by implementing an integrated suite of development tools supported by Azure. This will streamline your software lifecycle, from planning to deployment.",
    },
    {
      icon: Cog6ToothIcon,
      label: "Azure Data & Analytics",
      description:
        "We leverage Azure’s robust data and analytics services to deliver transformative insights. Our services aim at assisting your business in harnessing the power of data to facilitate informed decision-making.",
    },
    {
      icon: CloudArrowDownIcon,
      label: "Azure Backup and Site Recovery",
      description:
        "We aid businesses in implementing effective disaster recovery strategies, ensuring business continuity even in adverse situations. With Azure Backup and Site Recovery, your data is always secure.",
    },
    {
      icon: ComputerDesktopIcon,
      label: "Azure Security Services",
      description:
        "Get peace of mind with our Azure security services where we ensure enhanced security management and threat protection, safeguarding your business assets on the Azure platform.",
    },
  ];

  const azureOfferingValues = [
    {
      icon: CloudArrowDownIcon,
      img: null,
      img_link: null,
      label: "Azure Cloud Optimization Assessment",
      description: "Obtain a transparent view of your current Azure posture and a realistic pathway to getting more out of Azure through the Ravl Waypoint Cloud Assessment. The Ravl Waypoint Cloud Assessment provides technology and engineering leaders with a practical assessment of how effectively their Azure assets and environments are optimized and organized.",
    },
    {
      icon: Square3Stack3DIcon,
      img: null,
      img_link: null,
      label: "Azure Comprehensive Services",
      description: "Ravl powers the Waypoint Assessment with our extensive experience designing, engineering and running Azure services and solutions in highly regulated organizations and focuses our guidance across five fundamental pillars: Security & Compliance, Cost Management & ROI, Utilization and Sizing, Architecture, and Automation.",
    },
    {
      icon: ComputerDesktopIcon,
      img: partnershipMarketPlace,
      img_link: "https://azuremarketplace.microsoft.com/en-us/marketplace/consulting-services/ravl1657806179209.ravl-adv-0001",
      label: "Azure Marketplace Solutions",
      description: "The Waypoint Assessment team delivers a comprehensive view of your Azure posture today, which underpins your initial Ravl Waypoint providing the foundations for the first leg of your Azure improvement journey.",
    },
  ];

  useEffect(() => {
    const azureCaseStudies = filterCaseStudiesBySection("azure");
    setSelectedCaseStudies(azureCaseStudies);
  }, []);

  return (
    <div className="container mx-auto pt-8 px-6 sm:px-12 md:px-24 lg:px-28">
      <div className="my-6">
        <h1 className="text-4xl font-bold mb-10 text-gray-900 sm:text-5xl">
          Our <span className="text-purple-700">Microsoft Azure</span> Partnership and Services
        </h1>
        <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center gap-4">
          <img src={partnershipDigitalAppImg} alt="Digital & App Innovation Azure" className="w-full max-w-xs lg:max-w-sm h-auto" />
          <img src={partnershipDataAIImg} alt="Data & AI Azure" className="w-full max-w-xs lg:max-w-sm h-auto" />
        </div>
        <p className="mt-14">
          RAVL is your trusted partner for Azure consulting services. We specialize in helping highly regulated, financial services and insurance organizations optimize their cloud infrastructure to achieve their business goals.
        </p>
      </div>

      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700">
          Our Azure Services
        </h3>
        <p className="mt-2">
          At RAVL, we offer a complete range of Azure consulting services to help you unlock the full potential of Azure and achieve your business goals.
        </p>
      </div>
      <div className="my-14">
        <BulletCardList values={services} />
      </div>

      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700">
          Our Microsoft Azure Offerings
        </h3>
        <div className="mt-2">
          {azureOfferingValues.map((offering, index) => (
            <div
              className={`card text-left max-w-3xl self-center my-8 ${index % 2 ? "ml-auto" : "mr-auto"}`}
              key={index}
            >
              <div className="flex flex-col md:flex-row items-start gap-4">
                <div className="flex-grow flex flex-col justify-center">
                  <div className="flex items-center gap-4">
                    {offering.icon && (
                      <offering.icon
                        aria-hidden="true"
                        focusable="false"
                        className="w-6 h-6 stroke-purple-500 fill-transparent"
                      />
                    )}
                    <h4 className="font-semibold text-purple-700">{offering.label}</h4>
                  </div>
                  <p className="text-sm text-gray-600">{offering.description}</p>
                </div>
                {offering.img && offering.img_link && (
                  <a
                  href={offering.img_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-0 md:w-auto w-full"
                >
                  <img
                    src={offering.img}
                    alt="Azure Offering"
                    className="w-3/4 md:w-1/4 lg:w-full h-auto mx-auto md:mx-0"
                  />
                </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700">
          Why Choose RAVL?
        </h3>
        <p className="mt-2">
          At RAVL, we have a team of Azure experts with extensive experience in working with highly regulated industries. Our focus is on delivering customized solutions that meet your specific needs and goals. We are committed to providing excellent customer service and support to help you achieve success with Azure.
        </p>
      </div>
      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700 mb-8">
          The Work We've Done:
        </h3>
        <Carousel caseStudies={selectedCaseStudies} />
        <div className="my-14">
          <h3 className="text-lg sm:text-xl font-semibold text-purple-700 mt-10">
            Let's Talk!
          </h3>
          <p className="mt-2">
            If you're ready to take your business to the next level with Azure, contact us today to schedule a consultation with one of our Azure experts. Let us help you unlock the full potential of Azure and achieve your business goals with confidence.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Azure;
