import React from "react";
import { Carousel } from "react-responsive-carousel";
import BulletCardList from "../common/BulletCard/BulletCardList";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const CarouselComponent = ({ caseStudies }) => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      autoPlay={true}
      interval={5000}
      transitionTime={500}
      emulateTouch={true}
      swipeable={true}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            onClick={onClickHandler}
            title={label}
            className="absolute z-10 left-0 md:left-20 top-1/2 transform -translate-y-1/2 bg-gray-600 bg-opacity-50 text-white px-4 py-2 rounded-lg"
          >
            &lt;
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            onClick={onClickHandler}
            title={label}
            className="absolute z-10 right-0 md:right-20 top-1/2 transform -translate-y-1/2 bg-gray-600 bg-opacity-50 text-white px-4 py-2 rounded-lg"
          >
            &gt;
          </button>
        )
      }
    >
      {caseStudies.map((caseStudy) => (
        <div key={uuidv4()} className="p-2 mx-auto max-w-xs">
          <Link to={`/our-work/${caseStudy.caseStudyName}`}>
            <div className="bg-gray-100 relative h-80 w-80">
              {" "}
              <img
                src={caseStudy.image}
                className="w-full h-full rounded-md object-cover"
                alt={caseStudy.alt}
              />
              <div className="absolute bottom-0 left-0 p-4 bg-opacity-70 bg-black text-white rounded-md">
                {" "}
                <h3 className="text-lg font-bold">{caseStudy.title}</h3>
                <p>{caseStudy.description}</p>
              </div>
            </div>
            <div className="group flex items-center gap-x-6 text-sm leading-6">
              {/* <BulletCardList values={caseStudy.whatWeDid} /> */}
            </div>
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
