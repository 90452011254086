import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TabItem = ({ item }) => (
  <Tab
    className={({ selected }) =>
      classNames(
        "group card flex justify-center items-center gap-4 cursor-pointer",
        selected ? "border-purple-700 bg-purple-50" : "hover:bg-purple-50"
      )
    }
  >
    <div className="flex h-12 w-12 flex-none items-center justify-center">
      <item.icon
        className="h-8 w-8 text-gray-600 group-hover:text-purple-700"
        aria-hidden="true"
      />
    </div>
    <div className="flex-auto text-left">
      <h6 className="font-bold text-base text-purple-700">
        {item.label[0]}
        <sup>{item.label[1]}</sup>
      </h6>
      <p className="text-sm text-gray-600">{item.description}</p>
    </div>
  </Tab>
);

export default TabItem;
