import { Link } from "react-router-dom";
import React from "react";

const ErrorPage = () => {
  return <section className="h-full flex grow items-center">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="flex flex-col items-center gap-4 mx-auto max-w-screen-sm text-center">
        <h1 className="text-7xl tracking-tight font-extrabold lg:text-9xl text-purple-700">404</h1>
        <p className="text-3xl tracking-tight font-bold text-gray-900 md:text-4x">Something's missing.</p>
        <p className="text-lg text-gray-500">Sorry, we can't find that page. You'll find lots to explore on the home page.</p>
        <Link to="/" className="btn-primary sm:w-48">
          Back to Home page
        </Link>
      </div>
    </div>
  </section>;
};

export default ErrorPage;
