import { useState } from "react";
import { Link, useMatch } from "react-router-dom";

// Assets
import LinkedinLogo from "../../assets/images/svg/linkedin.svg";
import MicrosoftLogo from "../../assets/images/microsoft-partner.png";
import AWSLogo from "../../assets/images/aws-partner.png";

// Components
import PrivacyModal from "../common/PrivacyModal";

// Data
const partners = [
  {
    link: "/partners/aws",
    alt: "AWS logo",
    img: AWSLogo,
  },
  {
    link: "/partners/microsoftazure",
    alt: "Microsoft Logo",
    img: MicrosoftLogo,
  },
];

const Footer = () => {
  let [isOpen, setIsOpen] = useState(false);
  const match = useMatch("partners/:partner");
  const partner = match?.params.partner;

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <footer className="w-full max-w-screen-2xl mx-auto p-4 md:px-6">
      <PrivacyModal isOpen={isOpen} closeModal={closeModal} />
      <div className="lg:flex lg:items-center lg:justify-between">
        <ul className="flex flex-1 justify-center lg:justify-start flex-wrap items-center my-6 text-sm font-medium text-gray-500 lg:my-0">
          <li
            className="mr-4 hover:underline md:mr-6 cursor-pointer"
            tabIndex={0}
          >
            <button
              type="button"
              onClick={openModal}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  openModal();
                }
              }}
            >
              Privacy
            </button>
          </li>
          <li>
            <a className="hover:underline" href="mailto:info@ravl.io">
              info@ravl.io
            </a>
          </li>
        </ul>

        <div className="flex flex-1 flex-wrap sm:flex-nowrap justify-center lg:justify-end ml-auto gap-4">
          {partners.map((item) =>
            item.link?.includes(partner) || !partner ? (
              <Link to={item.link} key={item.alt} aria-label={item.alt}>
                <img
                  src={item.img}
                  alt={item.alt}
                  className="h-16 aspect-auto"
                  aria-hidden="true"
                />
              </Link>
            ) : null
          )}
        </div>
      </div>

      <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />

      <div className="flex flex-col justify-center items-center">
        <a
          className="mb-4"
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.linkedin.com/company/ravl-io/"
          aria-label="LinkedIn"
        >
          <img
            src={LinkedinLogo}
            alt="LinkedIn Logo"
            className="h-8 w-8"
            aria-hidden="true"
          />
        </a>

        <span className="block text-sm text-gray-500 sm:text-center">
          © 2023 All Rights Reserved.{" "}
          <a href="https://ravl.io/" className="hover:underline">
            RAVL Inc.
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
