import React from "react";
import { v4 as uuidv4 } from "uuid";
// Components
import Card from "./BulletCard";

const BulletCardList = ({ values }) => {
  return (
    <ul className="flex flex-row flex-wrap basis-1/2 justify-center w-100">
      {values.map((item) => (
        <li
          key={uuidv4()}
          className="flex py-2 md:p-2 w-full md:w-1/2 lg:w-1/3"
        >
          <Card item={item} className="flex-1" />
        </li>
      ))}
    </ul>
  );
};

export default BulletCardList;
