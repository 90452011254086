import { Tab } from "@headlessui/react";
import TabItem from "./TabItem";
import BreakdownDetails from "./BreakdownDetails";

const BuildBreakdownSection = ({
  build,
  targetElementRef,
  selectedTab,
  onTabChange,
}) => (
  <section className="flex flex-col gap-8 xl:px-32">
    <h2 className="text-4xl font-bold leading-[1.15] text-black sm:text-6xl sm:leading-[1.15] mb-8">
      <span className="text-purple-700">How</span> we like to{" "}
      <span className="text-purple-700">Build</span>
    </h2>
    <div className="text-gray-600 sm:text-xl">
      RAVL delivers business outcomes as opposed to technology inputs. We
      provide teams (pods) of engineers who own the delivery of your technology
      solutions.
    </div>
    <div className="text-gray-600 sm:text-xl">
      Once the work is complete,{" "}
      <span className="text-purple-700 font-bold">
        you can hire a subset of the team{" "}
      </span>
      ensuring that you have the skills and knowledge required to support the
      solution and continue the journey.
    </div>
    <div className="text-gray-600 sm:text-xl">
      Solving complex technology problems, sustainably is how{" "}
      <span className="text-purple-700 font-bold">RAVL Builds. Better. </span>
    </div>

    <Tab.Group selectedIndex={selectedTab} onChange={onTabChange}>
      <Tab.List className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-16">
        {build.map((item, index) => (
          <TabItem key={index} item={item} />
        ))}
      </Tab.List>

      <Tab.Panels ref={targetElementRef}>
        {build.map((item, index) => (
          <Tab.Panel key={index}>
            <BreakdownDetails item={item} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  </section>
);

export default BuildBreakdownSection;
