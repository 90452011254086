import kubernetesLogoWithoutName from "../assets/svg/kubernetes-logo-without-name.svg";
import BulletCardFlexList from "../components/common/BulletCard/BulletCardFlexList";
import "../styles/Kubernetes.css"; // Importing the custom CSS

import {
  ShieldCheckIcon,
  ArrowRightOnRectangleIcon,
  ArrowPathRoundedSquareIcon,
  MagnifyingGlassCircleIcon,
  PresentationChartLineIcon,
  ArrowsUpDownIcon,
  QueueListIcon,
  CloudIcon,
  SquaresPlusIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/outline";

const KUBERNETES = () => {
  const kubernetesServicesValues = [
    {
      icon: SquaresPlusIcon,
      label: "Cluster Setup and Management",
      description:
        "Design, deploy, and manage scalable and secure Kubernetes clusters across cloud and on-prem environments.",
    },
    {
      icon: ArrowRightOnRectangleIcon,
      label: "Kubernetes Migration",
      description:
        "Seamlessly migrate your workloads from VMs or other orchestrators to Kubernetes while minimizing downtime.",
    },
    {
      icon: ShieldCheckIcon,
      label: "Kubernetes Security and Compliance",
      description:
        "Implement security best practices, RBAC policies, and ensure compliance with industry standards in your Kubernetes environments.",
    },
    {
      icon: ArrowPathRoundedSquareIcon,
      label: "CI/CD Pipelines with Kubernetes",
      description:
        "Build automated CI/CD pipelines using Kubernetes, Helm, and GitOps to streamline deployment and version control.",
    },
    {
      icon: MagnifyingGlassCircleIcon,
      label: "Monitoring and Observability",
      description:
        "Gain real-time insights into your Kubernetes clusters with Prometheus, Grafana, and other observability tools.",
    },
    {
      icon: QueueListIcon,
      label: "Stateful Applications in Kubernetes",
      description:
        "Run and manage stateful applications like databases and message queues in Kubernetes using StatefulSets and Persistent Volumes.",
    },
    {
      icon: CloudIcon,
      label: "Hybrid Cloud Kubernetes",
      description:
        "Leverage Kubernetes to unify your on-prem and cloud resources for a consistent hybrid cloud strategy.",
    },
  ];

  const kubernetesOfferingValues = [
    {
      icon: PresentationChartLineIcon,
      label: "Kubernetes Adoption Strategy",
      description:
        "Build a tailored Kubernetes adoption roadmap to align with your organizational goals and maximize the benefits of container orchestration.",
    },
    {
      icon: ShieldCheckIcon,
      label: "Security-First Kubernetes Architecture",
      description:
        "Design your Kubernetes architecture with security as a top priority, leveraging network policies, security contexts, and best practices.",
    },
    {
      icon: ArrowsUpDownIcon,
      label: "Kubernetes Scaling and High Availability",
      description:
        "Ensure your Kubernetes clusters can scale on-demand and maintain high availability, using auto-scaling and load-balancing techniques.",
    },
    {
      icon: DocumentChartBarIcon,
      label: "Kubernetes Cost Optimization",
      description:
        "Optimize your Kubernetes infrastructure costs by rightsizing resources, automating scaling, and leveraging spot instances where applicable.",
    },
  ];

  return (
    <section className="px-6 py-6 text-center md:px-12 md:py-12">
      <div className="container mx-auto pt-8 px-6 sm:px-12 md:px-24 lg:px-28">
        <div className="my-6">
          <h1 className="text-4xl font-bold mb-6 text-gray-900 sm:text-5xl text-center">
            Our <span className=" text-purple-700">Kubernetes</span> Services
            and Offerings
          </h1>
          <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center gap-4">
            <img
              src={kubernetesLogoWithoutName}
              alt="Digital & App Innovation Azure"
              className="w-full max-w-[10rem] lg:max-w-[12rem] h-auto"
            />
            {/* TODO: Add in secondary logo when Rob is ready */}
            {/* <img
              src={cloudNativeComputingFoundation}
              alt="Data & AI Azure"
              className="w-full max-w-[14rem] lg:max-w-[20rem] h-auto"
            /> */}
          </div>

          {/* TODO: Add a placeholder announcement here when Rob is ready*/}
          {/* <p className="mt-16 text-xl">
            <span className=" text-purple-700 font-bold">
              RAVL placeholder announcement.
            </span>{" "}
            <br></br>
            {/* We offer a complete range of Kubernetes consulting services to help
            you unlock the full potential of Kubernetes and achieve your
            business goals. */}
        </div>
        <div className="my-14">
          <h3 className="text-xl sm:text-2xl font-semibold text-purple-700">
            Our Kubernetes Services
          </h3>
          <p className="mt-2">
            At RAVL, we offer a complete range of Kubernetes consulting services
            to help you unlock the full potential of Kubernetes and achieve your
            business goals.
          </p>
        </div>
        <div className="my-14">
          <BulletCardFlexList values={kubernetesServicesValues} />
        </div>

        <div className="my-14">
          <h3 className="text-xl sm:text-2xl font-semibold text-purple-700">
            Our Kubernetes Offerings
          </h3>
          <p className="mt-2">
            Our Kubernetes offerings provide tailored solutions designed to meet
            the unique needs of your business. From scaling infrastructure to
            improving security and observability, we ensure your Kubernetes
            environment is optimized for high performance and reliability.
          </p>
        </div>
        <div className="container mx-auto py-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {kubernetesOfferingValues.map((offering, index) => (
              <div key={index} className="p-3 bg-white shadow-lg rounded-lg">
                <div className="flex items-center mb-4">
                  <div className="p-4 bg-purple-200 rounded-md card-icon-holder">
                    <offering.icon className="h-6 w-6 text-purple-700" />
                  </div>
                  <h3 className="ml-4 text-lg font-semibold text-purple-700">
                    {offering.label}
                  </h3>
                </div>
                <p className="text-gray-600 text-left">
                  {offering.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="my-14">
          <h3 className="text-xl sm:text-2xl font-semibold text-purple-700">
            Why Choose RAVL?
          </h3>
          <p className="mt-2">
            At RAVL, we have a team of Kubernetes experts with extensive
            experience in working with highly regulated industries. Our focus is
            on delivering customized solutions that meet your specific needs and
            goals. We are committed to providing excellent customer service and
            support to help you achieve success with Kubernetes.
          </p>
        </div>
      </div>
    </section>
  );
};

export default KUBERNETES;
