import React from "react";
import BulletCardList from "../components/common/BulletCard/BulletCardList";
import { useParams, useNavigate } from "react-router-dom";
import { caseStudies } from "../data";
import { useState, useEffect } from "react";

const CaseStudy = () => {
  const { caseStudyName } = useParams();
  const navigate = useNavigate();
  const [caseStudyData, setCaseStudyData] = useState(null);

  useEffect(() => {
    const matchingCaseStudy = caseStudies.find(
      (caseStudy) => caseStudy.caseStudyName === caseStudyName
    );
    if (!matchingCaseStudy) {
      return navigate("/*");
    }
    setCaseStudyData(matchingCaseStudy);
  }, [caseStudyName]);

  if (!caseStudyData) {
    return <div>Loading</div>;
  }
  return (
    <div className="container mx-auto p-8 space-y-10">
      <div
        style={{
          backgroundImage: `url(${caseStudyData.caseStudyImage})`,
          height: "400px",
        }}
        className="rounded-lg w-full bg-center bg-cover relative"
        aria-label="office coworkers"
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <h1 className="text-4xl font-bold text-white absolute inset-0 flex items-center justify-center p-5">
          {caseStudyData.title}
        </h1>
      </div>
      <div className="bg-gray-100 p-6 rounded-lg space-y-4">
        <h2 className="text-2xl font-semibold text-purple-700">
          Engagement Snapshot
        </h2>
        <p className="text-gray-700 leading-relaxed">
          {caseStudyData.engagementSnap}
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg space-y-4">
        <div className="">
          <BulletCardList values={caseStudyData.overviewValues} />
        </div>
      </div>
      <div className="bg-gray-100 p-6 shadow-sm rounded-lg space-y-4">
        <h2 className="text-2xl font-semibold text-purple-700">
          Client Challenges
        </h2>
        <ul className="list-disc pl-5 text-gray-700 leading-relaxed space-y-2">
          {caseStudyData.clientChallenges.map((issue, index) => (
            <li>
              <strong>{issue.key}: </strong>
              {issue.value}
            </li>
          ))}
        </ul>
      </div>
      <div className="order-2 md:order-1 bg-white p-6 shadow-sm rounded-lg space-y-4">
        <h2 className="text-2xl font-semibold text-purple-700">What We Did</h2>
        <ul className="list-disc pl-5 text-gray-700 leading-relaxed space-y-2">
          {caseStudyData.whatWeDid.map((solution, index) => (
            <li>
              <strong>{solution.key}: </strong>
              {solution.value}
            </li>
          ))}
        </ul>
      </div>

      <div className="bg-gray-100 shadow-sm p-6 rounded-lg space-y-4">
        <h2 className="text-2xl font-semibold text-purple-700">
          The RAVL Impact
        </h2>
        <div className="flex flex-wrap gap-2 md:gap-4 lg:gap-8 justify-center text-center">
          {caseStudyData.resultValues.map((card) => (
            <div
              key={card.label}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 border p-4 shadow-lg rounded-md flex flex-col items-center space-y-2 bg-white"
            >
              <h3 className="text-purple-700 text-xl md:text-3xl p-4 font-semibold">
                {card.label}
              </h3>
              <p className="mt-1 text-sm text-gray-600">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
