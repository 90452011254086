import React from "react";

const BulletCard = ({ item }) => {
  return (
    <div className="card group flex items-center gap-x-6 text-sm leading-6 w-full">
      {item.icon ? (
        <div className="flex h-12 w-12 flex-none items-center justify-center">
          <item.icon
            className="h-8 w-8 text-gray-600 group-hover:text-purple-700"
            aria-hidden="true"
          />
        </div>
      ) : null}
      <div className="flex-auto text-left">
        <p className=" font-bold text-base text-purple-700">{item.label}</p>
        <p className="text-sm text-gray-600">{item?.description}</p>
      </div>
    </div>
  );
};

export default BulletCard;
