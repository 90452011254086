import { Link } from "react-router-dom";
import IconLink from "../common/IconLink";

// Assets
import advisoryPhoto from "../../assets/images/team-fallback.jpg";
import buildPhoto from "../../assets/images/people-fallback.jpg";
import buildXPhoto from "../../assets/images/main-buildx.jpeg";

// Icons
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const RAVLOfferings = () => {
  return (
    <section
      id="RAVL-Offerings"
      className="flex flex-col items-center gap-6 md:gap-11 p-4 md:p-8 pt-20 md:pt-[88px]"
    >
      <div className="flex flex-col items-center gap-4 text-center w-full max-w-xl">
        <h2 className="text-4xl font-bold leading-[1.15] text-black sm:text-6xl sm:leading-[1.15]">
          What we do
        </h2>
        <h3 className="text-gray-600 sm:text-xl">
          We help organizations tackle their most ambitious projects and evolve
          their capabilities through our Advisory and Build services.
        </h3>
      </div>
      <div className="flex flex-col items-center gap-6 max-w-6xl">
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-4 md:gap-6">
          <div className="card flex flex-col p-8 gap-4 justify-between lg:text-left w-full h-auto">
            <div className="flex flex-col gap-5 lg:text-left w-full">
              <h3 className="capitalize text-purple-700 text-3xl font-bold">
                Advisory
              </h3>
              <h4 className="text-2xl font-bold">Assess. Transform. Enable.</h4>
              <p>
                We partner with organizations to navigate their toughest
                challenges. We quickly assess the situation, define the required
                transformation, and enable lasting change.
              </p>
              <h5 className="text-xl font-bold pb-1 border-b border-purple-700">
                {" "}
                Our core capabilities include:
              </h5>
              <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2">
                <ul className="flex flex-col gap-[2px]">
                  <li>Architecture</li>
                  <li>Bus. & tech capability mapping</li>
                  <li>Business casing</li>
                  <li>Cost Optimization</li>
                  <li>Customer journey mapping</li>
                  <li>Governance</li>
                </ul>
                <ul className="flex flex-col gap-[2px]">
                  <li>Operating models</li>
                  <li>Process improvement/automation</li>
                  <li>Technical assessments/root cause analysis</li>
                  <li>Technology selection</li>
                  <li>Technology strategy & roadmaps</li>
                </ul>
              </div>
            </div>
            <IconLink
              link="/services/advisory"
              text="Explore Advisory"
              icon={<ArrowRightIcon className="h-4" />}
            />
          </div>
          <div className="card flex w-full h-auto">
            <img
              src={advisoryPhoto}
              alt="people"
              className="shadow-lg rounded-lg w-full h-auto object-cover"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6">
          <div className="card flex w-full h-auto">
            <img
              src={buildPhoto}
              alt="people"
              className="shadow-lg rounded-lg w-full h-auto object-cover"
            />
          </div>
          <div className="card flex flex-col p-8 gap-4 justify-between lg:text-left w-full h-auto">
            <div className="flex flex-col gap-5 lg:text-left w-full">
              <h3 className="capitalize text-purple-700 text-3xl font-bold">
                Build
              </h3>
              <h4 className="text-2xl font-bold">Design. Architect. Build.</h4>
              <p>
                We're builders. We work with our clients to create and construct
                modern, elegant solutions. We turn their ideas into reality
                their tech talent.
              </p>
              <h5 className="text-xl font-bold pb-1 border-b border-purple-700">
                {" "}
                Our expertise lies in:
              </h5>
              <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2">
                <ul className="flex flex-col gap-[2px]">
                  <li>Solutions Architecture</li>
                  <li>Cloud Engineering</li>
                  <li>DevSecOps</li>
                  <li>Platform Engineering</li>
                </ul>
                <ul className="flex flex-col gap-[2px]">
                  <li>Site Reliability Engineering</li>
                  <li>Application Development</li>
                  <li>AI & Machine Learning</li>
                  <li>Infrastructure as Code</li>
                </ul>
              </div>
            </div>
            <IconLink
              link="/services/build"
              text="Explore Build"
              icon={<ArrowRightIcon className="h-4" />}
            />
          </div>
        </div>
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-4 md:gap-6">
          <div className="card flex flex-col p-8 gap-4 justify-between lg:text-left w-full h-auto">
            <div className="flex flex-col gap-5 lg:text-left w-full">
              <h3 className="capitalize text-purple-700 text-3xl font-bold">
                BuildX
              </h3>
              <h4 className="text-2xl font-bold">Sustain. Upskill. Empower.</h4>
              <p>
                Through our BuildX offering, we deliver top-tier technology
                solutions where our clients have the option to hire a subset of
                the team giving you the ability to continue the journey.
              </p>
              <h5 className="text-xl font-bold pb-1 border-b border-purple-700">
                {" "}
                BuildX combines the best of:
              </h5>
              <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2">
                <ul className="flex flex-col gap-[2px]">
                  <li>Outcome based delivery</li>
                  <li>Cloud & Platform Engineering</li>
                  <li>Software Engineering</li>
                </ul>
                <ul className="flex flex-col gap-[2px]">
                  <li>Mentorship</li>
                  <li>Hands On Learning Journeys</li>
                </ul>
              </div>
            </div>
            <IconLink
              link="/services/build#how-we-like-to-build"
              text="Explore BuildX"
              icon={<ArrowRightIcon className="h-4" />}
            />
          </div>
          <div className="card flex w-full h-auto">
            <img
              src={buildXPhoto}
              alt="people"
              className="shadow-lg rounded-lg w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RAVLOfferings;
